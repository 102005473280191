/* MIXINS */

@mixin mobile-first($min){
  @media only screen and (min-width: $min) {
    @content;
  }
}

@mixin min-max($min, $max){
  @media only screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin desktop-first($max){
  @media only screen and (max-width: $max) {
    @content;
  }
}

@mixin flexbox($justify:space-between, $align:center, $direction){
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin list-unstyle{
  margin: 0;
  padding-left: 0;
  list-style: none;
}

%button-unstyle{
  background-color: transparent;
  border: none;
}

@mixin link-unstyle($display:inline-block) {
  display: $display;
  text-decoration: none;
}

@mixin psevdoelements($display:inline-block, $width, $height, $content: ""){
  display: $display;
  width: $width;
  height: $height;
  content: $content;
}


@mixin bgImages($position-x:calc(50% + 0px), $repeat:no-repeat, $size){
  background-size: $size;
  background-position: $position-x center;
  background-repeat: $repeat;
}

@mixin  bgRetinaImages{
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) {
    @content;
  }
}


@mixin typography-styles($style:normal, $size:16px, $line-height:1.15,$weight:400, $text-align:left) {

  font-style: $style;
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
  text-align: $text-align;
}

%text-end{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}






