/* GENERAL */

* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
}
ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
a {
  text-decoration: none;
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
p {
  margin: 0;

}
img {
  display: block;
  // height: auto;
}
svg {
  display: block;
}
button {
  cursor: pointer;
}
#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Preahvihear", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  background-color: #11071f;
}

/* STICKY-FOOTER */
.site-main {
  flex-grow: 1;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9857D3;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2C1250;
}
