@import 'https://flackr.github.io/scroll-timeline/dist/scroll-timeline.js';
.site-hieadr {
  background: #1A0B2E;
  box-shadow: 0px 6px 22px -3px rgba(0, 0, 0, 0.1);
}
.haeader-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav__list {
  display: flex;
  justify-content: space-between;
}
.nav__link {
  padding: 10px 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color:#551a8b; 
  &:active {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ffffff 17.71%,
      rgba(67, 67, 67, 0) 100%
    );
    color: #ffffff;
    text-shadow: 1px 1px 5px #1a0b2e, 0 0 2em #1a0b2e, 0 0 0.3em #1a0b2e;
  }
  &:hover {
    color: #ffffff;
    text-shadow: 1px 1px 5px #1a0b2e, 0 0 2em #1a0b2e, 0 0 0.3em #1a0b2e;
  }
}
.nav__link--active {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ffffff 17.71%,
    rgba(67, 67, 67, 0) 100%
  );
  color: #ffffff;
  text-shadow: 1px 1px 5px #1a0b2e, 0 0 2em #1a0b2e, 0 0 0.3em #1a0b2e;
}
.test-box {
  background-color: #9857D3;
}
.test-title {
  padding: 10px 0;
  font-size: 1.5em;
  font-weight: bold;
  text-align:center;
  color: #ffff
}
.scroll-top {
  position:fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
  height: 6px;
  background-color: #4f228d;
}
.scroll {
  height: 100%;
  width: 100%;
  background-color: #9857D3;
  scale: 0 1;
  animation: scroll-watcher linear;
  transform-origin: left;
  animation-timeline: scroll(y);
}

@keyframes scroll-watcher {
  to {scale: 1 1}

}
.mbl-footer {
  display: none;
}
@include desktop-first(600px) {
  header,footer {
    display: none;
  }
  .mbl-footer {
    display: block;
    background-color: #251C31;
    background-image: url("../../../images/mbl-footer.svg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .sircle {
    border-radius: 50%;
    background-color: transparent;
    border: none;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateX(-0px);
    top: -50px;
    padding: 50px;
    width: 100px;
    height: 100px;
    background-image: url("../../../images/footer-logo.png");
    background-size: cover;
    background-position: center;
  }
  .btn-bottom {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
  }
  .logo-list {
    z-index: -1;
    width: 50px !important;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    .logo-item {
      a{
        transition: 0.3s linear;
      position: absolute;
      left: 0;
      right: 0;
        top: 0;
      margin: 0 auto;
        img {
          background-color: #9857D3;
          border-radius: 50%;
          border: 2px solid #9857D3;
        }
      }
    }
  }
  .list-active {
    .logo-item {
      a {
      left: -50px !important;
        top: -50px;
      }
      &:nth-child(2) {
        a {
        left: -26px !important;
        top: -77px;
        }
      }
      &:nth-child(3) {
        a {
          left: 10px !important;
          top: -86px;
        }
      }
      &:nth-child(4) {
        a {
          left: 45px !important;
          top: -77px;
        }
      }
      &:nth-child(5) {
        a {
          left: 70px !important;
          top: -50px;
        }
      }
    }
  }
}
