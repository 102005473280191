.hero {
  padding-top: 100px;
  background-image: url("../../../images/Gradient.png");
  background-repeat: no-repeat;
  background-position: calc(50% - 450px) calc(50% - 90px);
}

.hero__me-content {
  display: flex;
  align-items: flex-end;
}

.me-name {
  position: relative;
  font-weight: 400;
  font-size: 19px;
  line-height: 97px;
  color: #fff;
  margin-bottom: 83px;

  &::before {
    content: "";
    position: absolute;
    top: 30px;
    left: -100px;
    width: 92px;
    height: 82px;
    background-image: url("../../../images/Arrow.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.text-color {
  color: #7127ba;
}

.text {
  font-weight: 400;
  font-size: 17px;
  line-height: 31px;
  color: #fff;
}

.title {
  margin-left: 10px;
  width: 386px;
  font-weight: 400;
  font-size: 50px;
  line-height: 63px;
  color: #fff;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: 60px;
    bottom: 0;
    width: 188.58px;
    height: 57.9px;
    border: 1px solid #ffffff;
    transform: rotate(-4.74deg);
    border-radius: 50%;
  }
}

.dizayn-text {
  margin-left: 25px;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #fff;
  margin-bottom: 30px;
}

.content-title {
  margin-top: 51px;
  font-weight: 400;
  font-size: 50px;
  line-height: 90px;
  color: #fff;
}

.content-instagram {
  font-weight: 400;
  font-size: 21px;
  line-height: 38px;
  color: #fff;
  display: flex;
  align-items: center;
}

.instagram {
  display: flex;
  align-items: center;

  &::before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-image: url("../../../images/instagram.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 5px;
  }
}

.work-box__title {
  margin-top: 170px;
  margin-bottom: 44px;
  font-weight: 400;
  font-size: 40px;
  line-height: 72px;
  color: #fff;
}

.content-text {
  width: 100%;
  max-width: 892px;
  margin-top: 70px;
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  color: #fff;
}

.card-list {
  height: 400px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.brand-img {
  width: 100%;
  max-width: 895px;
}

.card {
  margin-bottom: 20px;
  width: 570px;
  padding: 20px;
  background-color: #2c1250;
  border-radius: 5px;
  border-top: 5px solid #4f228d;
  box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  display: flex;
  align-items: center;
}
.card__img {
  margin-right: 20px;
}

.card__btn {
  padding: 9px 21px;
  border: 1px solid #693b93;
  background-color: transparent;
  border-radius: 10px;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #fff;
}

.card__title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: #fff;
}

.card__text {
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  color: #fff;
  margin-bottom: 10px;
}

.work {
  background-image: url("../../../images/Gradient.png");
  background-repeat: no-repeat;
  background-position: calc(50% - 0px) calc(50% - 63px);
}

.currently__title {
  width: 100%;
  max-width: 809px;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #fff;
  text-align: center;
  margin: 96px auto 34px auto;
}

.currently__img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project__wrapper {
  width: 100%;
  margin-top: 61px;
}

.project__card {
  position: relative;
  display: flex;
  justify-content: space-between;

  &:nth-child(1) {
    margin-bottom: 223px;
  }

  &:nth-child(2) {
    margin-bottom: 223px;
    flex-direction: row-reverse;
    text-align: right;

    .project__img-box {
      padding: 24px 0 0 22px;
    }

    .project__box {
      right: 0;
      text-align: left;
    }

    .project__icon {
      margin-left: auto;
    }
  }
  &:nth-child(3) {
    margin-bottom: 223px;
    .project__icon{
    margin-top: 300px;
    }
  }
   &:nth-child(4) {
    margin-bottom: 223px;
    flex-direction: row-reverse;
    text-align: right;

    .project__img-box {
      padding: 24px 0 0 22px;
    }

    .project__box {
      right: 0;
      text-align: left;
    }

    .project__icon {
      margin-left: auto;
      margin-top: 290px;
    }
  }
}
.project__img-box {
  background-color: #2b215a;
  padding: 24px 24px 0 0;
  border-radius: 10px;
  width: 582.88px;
  height: 341px;
}

.project__img {
  filter: drop-shadow(14px -14px 19px rgba(255, 255, 255, 0.18));
}

.project__box {
  width: 667px;
  position: absolute;
  z-index: 10;
  padding: 30px 141px 59px 39px;
  background-color: #2c1250;
  border-radius: 20px;
}

.project__title {
  margin-top: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #9857d3;
}

.project__name {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  color: #ccd6f6;
}

.project__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ccd6f6;
}

.project__icon {
  width: 80px;
  margin-top: 220px;
  display: flex;
  justify-content: space-between;
}

.me__content {
  margin-top: 141px;
  margin-bottom: 95px;
}

.me__content--title {
  font-weight: 400;
  font-size: 25px;
  line-height: 45px;
  color: #fff;
}

.me__content--text {
  width: 416px;
  margin-top: 7px;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  color: #fff;
}

.seircle-group {
  width: 100%;
  max-width: 800px;
  top: 400px;
  transform: rotateX(70deg);
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;

}

.seircle-1 {
  z-index: 3;
  position: absolute;
  width: 100%;
  max-width: 800px;
  height: 800px;
  display: block;
  border: 4px solid rgba(118, 60, 172, 1);
  border-radius: 50%;
  transform: rotate(230deg);
  perspective-origin: 20% center;
  animation-name: seircle-1;
  animation-timing-function: linear;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-delay: 8s;

  .seircle-child {
    &::before {
      content: "";
      position: absolute;
      left: 70px;
      top: 300px;
      z-index: 1;
      // width: 25px;
      // height: 44px;
      width: 13px;
      height: 20.332px;
      background-image: url("../../../../assets/images/Group\ 1903.svg");
      background-size: contain;
      background-repeat: no-repeat;
      transform-style: preserve-3d;
      // transform: rotate3d(-21, 1, 3, 151deg);
      animation-name: h-1;
      animation-timing-function: linear;
      animation-duration: 15s;
      animation-iteration-count: infinite;
      animation-delay: 5s;
    }

    &::after {
      content: "";
      position: absolute;
      left: 200px;
      top: 400px;
      z-index: 1;
      // width: 25px;
      // height: 44px;
      width: 13px;
      height: 20.332px;
      background-image: url("../../../../assets/images/Group\ 1902.svg");
      background-size: contain;
      background-repeat: no-repeat;
      transform-style: preserve-3d;
      // transform: rotate3d(-21, 1, 3, 151deg);
      animation-name: h-1;
      animation-timing-function: linear;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      animation-delay: 10s;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 190px;
    z-index: 1;
    // width: 25px;
    // height: 44px;
    width: 13px;
    height: 20.332px;
    background-image: url("../../../../assets/images/css.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transform-style: preserve-3d;
    // transform: rotate3d(-21, 1, 3, 151deg);
    animation-name: h-1;
    animation-timing-function: linear;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-delay: 5s;
  }

  &::after {
    content: "";
    position: absolute;
    left: 25px;
    top: 600px;
    z-index: 1;
    // width: 25px;
    // height: 44px;
    width: 13px;
    height: 20.332px;
    background-image: url("../../../../assets/images/google.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transform-style: preserve-3d;
    // transform: rotate3d(-21, 1, 3, 151deg);
    animation-name: h-1;
    animation-timing-function: linear;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-delay: 10s;
  }
}

.oni {
  position: absolute;
  width: 100%;
  max-width: 700px;
  height: 700px;
  left: 50px;
  animation-direction: reverse;
  animation-delay: 5s;

  .seircle-child {
    &::before {
      content: "";
      position: absolute;
      left: 70px;
      top: 300px;
      z-index: 1;
      // width: 25px;
      // height: 44px;
      width: 13px;
      height: 20.332px;
      background-image: url("../../../../assets/images/Group\ 1904.svg");
      background-size: contain;
      background-repeat: no-repeat;
      transform-style: preserve-3d;
      // transform: rotate3d(-21, 1, 3, 151deg);
      animation-name: h-1;
      animation-timing-function: linear;
      animation-duration: 15s;
      animation-iteration-count: infinite;
      animation-delay: 5s;
    }

    &::after {
      content: "";
      position: absolute;
      left: 200px;
      top: 400px;
      z-index: 1;
      // width: 25px;
      // height: 44px;
      width: 13px;
      height: 20.332px;
      background-image: url("../../../../assets/images/Group\ \(2\).svg");
      background-size: contain;
      background-repeat: no-repeat;
      transform-style: preserve-3d;
      // transform: rotate3d(-21, 1, 3, 151deg);
      animation-name: h-1;
      animation-timing-function: linear;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      animation-delay: 10s;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 190px;
    width: 30px;
    height: 30px;
    background-image: url("../../../../assets/images/code.svg");
    // transform: rotate3d(-21, 1, 3, 151deg);
    background-size: contain;
    background-position: center;
    animation-name: h-2;
    animation-delay: 1s;
  }

  &::after {
    content: "";
    position: absolute;
    left: 20px;
    top: 600px;
    z-index: 1;
    // width: 25px;
    // height: 44px;
    width: 20px;
    height: 20.332px;
    background-image: url("../../../../assets/images/javascript.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transform-style: preserve-3d;
    // transform: rotate3d(-21, 1, 3, 151deg);
    animation-name: h-2;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 15s;
  }
}

.runi {
  position: absolute;
  width: 100%;
  max-width: 600px;
  height: 600px;
  left: 90px;
  animation-direction: alternate;
  animation-delay: 2s;

  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 190px;
    width: 40.093px;
    height: 40.867px;
    background-image: url("../../../../assets/images/react.svg");
    // transform: rotate3d(-21, 1, 3, 151deg);
    background-position: center;
    background-size: contain;
    animation-delay: 5s;
    animation-name: h-3;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0px;
    top: 500px;
    z-index: 1;
    // width: 25px;
    // height: 44px;
    width: 20px;
    height: 20px;
    background-image: url("../../../../assets/images/js.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transform-style: preserve-3d;
    // transform: rotate3d(-21, 1, 3, 151deg);
    animation-name: h-3;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 10s;
  }
}

.design-img {
  width: 100%;
  height: 750px;
  position: relative;
  overflow: hidden;
}

.center {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-48%);
  width: 542px;
  height: 657px;
  z-index: 22;
  background-image: url("../../../images/currently.png") ;
  // background-position: -10px -10px, center;

}

.center-shedov {
  position: absolute;
  top: 330px;
  left: 50%;
  transform: translate(-50%);
  width: 500px;
  height: 100px;
  border-radius: 50%;
  background-color: #11071f;
  box-shadow: 0px 0px 50px 150px #11071f;
  z-index: 1;
}
@keyframes seircle-1 {
  0% {
    transform: rotate(100deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes h-1 {
  0% {

    transform: rotate3d(-27, 4, 6, 174deg);
  }

  10% {
    transform: rotate3d(-20, 6, 4, 159deg);
  }

  20% {
    transform: rotate3d(-10, 6, -3, 199deg);
    width: 20px;
    height: 35px;
  }

  30% {
    transform: rotate3d(-17, 13, 3, 156deg);
  }

  40% {
    transform: rotate3d(-17, 19, 3, 162deg);
  }

  50% {
    transform: rotate3d(-17, 34, 6, 186deg);
  }

  60% {
    transform: rotate3d(-16, 55, 2, 182deg);
  }

  70% {
    transform: rotate3d(-16, 55, 2, 182deg);
  }

  80% {
    transform: rotate3d(-18, -7, 0, 182deg);
  }

  90% {
    transform: rotate3d(-18, -7, 0, 182deg);
  }

  100% {
    transform: rotate3d(-31, -4, 0, 167deg);
  }
}

@keyframes h-2 {
  0% {
    transform: rotate3d(-27, 4, 6, 174deg);
  }

  10% {
    transform: rotate3d(-20, 6, 4, 159deg);
  }

  20% {
    transform: rotate3d(-10, 6, -3, 199deg);
    width: 20px;
    height: 35px;
  }

  30% {
    transform: rotate3d(-17, 13, 3, 156deg);
  }

  40% {
    transform: rotate3d(-17, 19, 3, 162deg);
  }

  50% {
    transform: rotate3d(-17, 34, 6, 186deg);
  }

  60% {
    transform: rotate3d(-16, 55, 2, 182deg);
  }

  70% {
    transform: rotate3d(-16, 55, 2, 182deg);
  }

  80% {
    transform: rotate3d(-18, -7, 0, 182deg);
  }

  90% {
    transform: rotate3d(-18, -7, 0, 182deg);
  }

  100% {
    transform: rotate3d(-31, -4, 0, 167deg);
  }
}

@keyframes h-3 {
  0% {
    transform: rotate3d(-27, 4, 6, 174deg);
  }

  10% {
    transform: rotate3d(-20, 6, 4, 159deg);
  }

  20% {
    transform: rotate3d(-10, 6, -3, 199deg);
    width: 20px;
    height: 35px;
  }

  30% {
    transform: rotate3d(-17, 13, 3, 156deg);
  }

  40% {
    transform: rotate3d(-17, 19, 3, 162deg);
  }

  50% {
    transform: rotate3d(-17, 34, 6, 186deg);
  }

  60% {
    transform: rotate3d(-16, 55, 2, 182deg);
  }

  70% {
    transform: rotate3d(-16, 55, 2, 182deg);
  }

  80% {
    transform: rotate3d(-18, -7, 0, 182deg);
  }

  90% {
    transform: rotate3d(-18, -7, 0, 182deg);
  }

  100% {
    transform: rotate3d(-31, -4, 0, 167deg);
  }
}

.mbl-hero{
  mbl-footer{
    display: block;
  }
  margin-top: 50px;
  .hero__me-content {
    flex-direction: column;
    align-items: center;
    .hero__content {
      margin-left: 50px;
    }

    .me-name {
      text-align: center;
       &::before {
         transform: rotate(80deg);
         top: -30px;
         right: 0;
         left: 0;
         margin-left: 65%;
       }

    }
    .title {
      margin-left: -30px;
      scale: 0.8;
      font-size: 50px;

      }
      .content-instagram {
        display: block !important;
      }
  }
  .content-title {
    text-align: center;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: normal;
  }
  .hero__content {
    margin-left: 0px !important;
  }
  .content-instagram {text-align: center;
    flex-direction: column;
    align-items: center;
  }
  .content-text {
    margin-bottom: 100px;
  }
}
